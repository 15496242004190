import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { gedGuard } from '@core/guards/GED/ged.guard';
import { gestaoAcessoGuard } from '@core/guards/gestaoAcesso/gestao-acesso.guard';
import { limpezaConservacaoGuard } from '@core/guards/limpezaConservacao';
import { manutencaoGuard } from '@core/guards/manutencao/manutencao.guard';
import { radioProtecaoGuard } from '@core/guards/radioProtecao/radio-protecao.guard';
import { turnoGuard } from '@core/guards/turno';
import { HomeComponent } from '@shared/pages/home/home.component';
import { NaoEncontradoComponent } from '@shared/pages/nao-encontrado/nao-encontrado.component';

import { loginGuard } from './core/guards/login/login.guard';
import { MainComponent } from './shared/components/main/main.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    component: MainComponent,
    canActivateChild: [loginGuard],
    canActivate: [loginGuard],
    children: [
      {
        path: '',
        canActivate: [loginGuard],
        component: HomeComponent
      },
      {
        path: 'turno',
        canActivate: [turnoGuard],
        canActivateChild: [turnoGuard],
        loadChildren: () =>
          import('./modules/turno/turno.module').then(m => m.TurnoModule)
      },
      {
        path: 'radioProtecao',
        canActivate: [radioProtecaoGuard],
        canActivateChild: [radioProtecaoGuard],
        loadChildren: () =>
          import('./modules/radioProtecao/radio-protecao.module').then(
            m => m.RadioProtecaoModule
          )
      },
      {
        path: 'ged',
        canActivate: [gedGuard],
        canActivateChild: [gedGuard],
        loadChildren: () =>
          import('./modules/GED/ged.module').then(m => m.GedModule)
      },
      {
        path: 'manutencao',
        canActivate: [manutencaoGuard],
        canActivateChild: [manutencaoGuard],
        loadChildren: () =>
          import('./modules/manutencao/manutencao.module').then(
            m => m.ManutencaoModule
          )
      },
      {
        path: 'gestaoAcesso',
        canActivate: [gestaoAcessoGuard],
        canActivateChild: [gestaoAcessoGuard],
        loadChildren: () =>
          import('./modules/gestaoAcesso/gestao-acesso.module').then(
            m => m.GestaoAcessoModule
          )
      },
      {
        path: 'limpezaConservacao',
        canActivate: [limpezaConservacaoGuard],
        canActivateChild: [limpezaConservacaoGuard],
        loadChildren: () =>
          import(
            './modules/limpeza-conservacao/limpeza-conservacao.module'
          ).then(m => m.LimpezaConservacaoModule)
      }
    ]
  },
  {
    path: '**',
    component: NaoEncontradoComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
